import { useDateTimeFormatter } from '@/hooks/utils/use-date-time-formatter';
import { cn } from '@/utils/tailwind';
import { useTranslations } from 'next-intl';
import { useEffect, useRef, useState } from 'react';
import { ChatDataInterface } from './chat-bot';
import { ChatBotAvatar } from './chat-bot-avatar';
import { ChatBotMessage } from './chat-bot-message';

const options: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
};

export function ChatBotBody({
  chatData,
  isWaitingAIResponse,
}: {
  chatData: ChatDataInterface[];
  isWaitingAIResponse: boolean;
}) {
  const t = useTranslations('chatBotBody');

  const scrollRef = useRef<HTMLDivElement>(null);

  const [currentDate, _] = useState(new Date());

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [chatData, isWaitingAIResponse]);

  const { chatBotTimeFormatter } = useDateTimeFormatter([
    {
      name: 'chatBotTime',
      formatOption: options,
    },
  ]);

  const todayText = t('today_currentTime', {
    currentTime: chatBotTimeFormatter.format(currentDate),
  });

  return (
    <div
      ref={scrollRef}
      className="flex flex-1 flex-col space-y-4 overflow-y-auto px-4"
    >
      <div className="self-center text-neutral-500">{todayText}</div>

      {chatData.map((dialog) => {
        const isSender = dialog.type === 'sender';

        return (
          <div
            key={dialog.id}
            className={cn('flex gap-4', {
              'flex-row-reverse': isSender,
            })}
          >
            <ChatBotAvatar className={cn({ hidden: isSender })} />

            <div
              className={cn({
                'rounded-lg bg-cloudburst-100 px-4 py-2': isSender,
              })}
            >
              {isSender ? (
                <div>{dialog.content}</div>
              ) : (
                <ChatBotMessage message={dialog.content} />
              )}
            </div>
          </div>
        );
      })}

      {isWaitingAIResponse ? (
        <div className="flex items-start gap-4">
          <ChatBotAvatar />
          <div className="flex w-full flex-col gap-2">
            <div className="flex items-center gap-2" />
            <div className="h-5 w-full animate-pulse rounded-lg bg-gradient-to-r from-cloudburst-100 from-45% to-cloudburst-150 to-100%" />
            <div className="h-5 w-full animate-pulse rounded-lg bg-gradient-to-r from-cloudburst-100 from-45% to-cloudburst-150 to-100%" />
            <div className="h-5 w-[60%] animate-pulse rounded-lg bg-gradient-to-r from-cloudburst-100 from-45% to-cloudburst-150 to-100%" />
          </div>
        </div>
      ) : null}
    </div>
  );
}
