import { validateSchema } from '@/schema/validate-schema';
import { useTravelBootstrapData } from '@/store/store';
import { DATE_PICKER_MIN_DAY_OFFSET } from '@/utils/date-picker';
import { getClientUrl } from '@/utils/get-client-url';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { useAuthFetchKy } from '../fetch/use-auth-fetch-ky';

export const chatBotResponseSchema = z.object({
  data: z.array(
    z.object({
      userInput: z.string(),
      assistantOutput: z.string(),
    }),
  ),
  meta: z.object({
    count: z.number(),
  }),
});

type UseChatBotProps = {
  message: string;
  context: Record<string, string>;
};

export type ChatBotResponse = z.infer<typeof chatBotResponseSchema>;

export function useChatBot() {
  const baseUrl = getClientUrl();
  const authFetch = useAuthFetchKy;

  const travelBootstrapData = useTravelBootstrapData();
  const minFlightDays = travelBootstrapData?.flightMinDaysToBookInAdvance;
  const minHotelDays = travelBootstrapData?.hotelMinDaysToBookInAdvance;
  const minCarDays = travelBootstrapData?.carMinDaysToBookInAdvance;

  return useMutation({
    mutationFn: ({ message, context }: UseChatBotProps) => {
      return authFetch(`${baseUrl}/api/messages`, {
        method: 'POST',
        body: JSON.stringify({
          input: message,
          context: {
            ...context,
            flightMinDaysToBookInAdvance:
              normalizeMinDaysToBookInAdvance(minFlightDays),
            hotelMinDaysToBookInAdvance:
              normalizeMinDaysToBookInAdvance(minHotelDays),
            carMinDaysToBookInAdvance:
              normalizeMinDaysToBookInAdvance(minCarDays),
          },
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(async (res) => await res.json())
        .then((data) => {
          return validateSchema({
            data,
            schemaName: 'chat-bot-response',
            zodSchema: chatBotResponseSchema,
          });
        });
    },
  });
}

function normalizeMinDaysToBookInAdvance(minDays?: number) {
  return minDays && Number(minDays) >= DATE_PICKER_MIN_DAY_OFFSET
    ? minDays
    : DATE_PICKER_MIN_DAY_OFFSET;
}
