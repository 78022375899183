import {
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory';

// Workaround a Typescript error
// https://github.com/microsoft/TypeScript/issues/42873#issuecomment-885815290
import { LoyaltyProgramType } from '@/schema/loyalty-program.schema';

type SearchParam<T> = T & Record<string, string | number>;

// queries/users.ts
export const giftCardKeys = createQueryKeys('giftCards', {
  // we use type variable here to let the consumer control the actual type
  // so we don't need to import GC related type in this shared/global query keys
  // hence there is no circular dependencies between query key factory and use*** hooks
  list: <T>(searchParams: SearchParam<T>) => {
    return {
      queryKey: [searchParams],
      // query factory can also handle the api call here but we cannot use
      // the useAuthFetch here. We need to implement authFetch without hook to apply this pattern
      // queryFn: () => api.getUser(userId),
    };
  },
  detail: (giftCardId: string) => ({
    queryKey: [giftCardId],
  }),
});

export const loyaltyProgram = createQueryKeys('loyaltyProgram', {
  detail: (loyaltyProgramId: string) => {
    return {
      queryKey: [loyaltyProgramId],
    };
  },
});

export const pointsActivity = createQueryKeys('pointsActivity', {
  list: <T>(searchParams: SearchParam<T>) => {
    return {
      queryKey: [searchParams],
    };
  },
});

export const order = createQueryKeys('order', {
  detail: (orderId: string) => {
    return {
      queryKey: [orderId],
    };
  },
  list: <T>(searchParams: SearchParam<T>) => {
    return {
      queryKey: [searchParams],
    };
  },
});

export const orderItem = createQueryKeys('orderItem', {
  detail: (orderItemId: string) => {
    return {
      queryKey: [orderItemId],
    };
  },
  list: <T>(searchParams: SearchParam<T>) => {
    return {
      queryKey: [searchParams],
    };
  },
});

export const redeemAgainItem = createQueryKeys('redeemAgainItem', {
  list: <T>(searchParams: SearchParam<T>) => {
    return {
      queryKey: [searchParams],
    };
  },
});

export const availableToRedeemItem = createQueryKeys('availableToRedeem', {
  list: <T>(searchParams: SearchParam<T>) => {
    return {
      queryKey: [searchParams],
    };
  },
});

export const pointsSummary = createQueryKeys('pointsSummary', {
  detail: () => ({
    queryKey: ['pointsSummary'],
  }),
});

export const membership = createQueryKeys('membership', {
  detail: (membershipId: string) => {
    return {
      queryKey: [membershipId],
    };
  },
});

export const offer = createQueryKeys('offer', {
  list: <T>(searchParams: SearchParam<T>) => {
    return {
      queryKey: [searchParams],
    };
  },
  brands: <T>(searchParams: SearchParam<T>) => {
    return {
      queryKey: ['offer-brand', searchParams],
    };
  },
  detail: (offerId: string) => {
    return {
      queryKey: [offerId],
    };
  },
  affiliateLink: (offerId: string) => {
    return {
      queryKey: ['offer-affiliate-link', offerId],
    };
  },
});

export const dining = createQueryKeys('dining', {
  recentlySearched: (bucket: string) => {
    return {
      queryKey: [bucket],
    };
  },
  recentlyViewed: [''],
});

export const carLocation = createQueryKeys('carLocation', {
  list: <T>(searchParams: SearchParam<T>) => {
    return {
      queryKey: [searchParams],
    };
  },
});

export const car = createQueryKeys('car', {
  list: <T>(searchParams: SearchParam<T>) => {
    return {
      queryKey: [searchParams],
    };
  },
  detail: (carId: string) => {
    return {
      queryKey: [carId],
    };
  },
});

export const topic = createQueryKeys('topic', {
  list: ['topic'],
  detail: (topicId: string) => {
    return {
      queryKey: [topicId],
    };
  },
});

export const staticDocument = createQueryKeys('staticDocument', {
  list: ['staticDocument'],
  documentDetails: (documentName: string) => {
    return {
      queryKey: [documentName],
    };
  },
  termsAndConditionsModal: null,
  detail: (staticDocumentId: string) => {
    return {
      queryKey: [staticDocumentId],
    };
  },
});

export const question = createQueryKeys('question', {
  list: <T>(searchParams: SearchParam<T>) => {
    return {
      queryKey: [searchParams],
      // query factory can also handle the api call here but we cannot use
      // the useAuthFetch here. We need to implement authFetch without hook to apply this pattern
      // queryFn: () => api.getUser(userId),
    };
  },
  detail: (questionId: string) => {
    return {
      queryKey: [questionId],
    };
  },
});

export const productListing = createQueryKeys('products', {
  list: (type: LoyaltyProgramType, searchParams?: string) => ({
    queryKey: [type, searchParams],
  }),
});

export const travelFlights = createQueryKeys('travelFlights', {
  search: (queryKey: string) => {
    return {
      queryKey: [queryKey],
    };
  },
  searchAirlines: (queryKey: string) => {
    return {
      queryKey: [queryKey],
    };
  },
  prices: (url: string) => {
    return {
      queryKey: ['flight-prices', url],
    };
  },
  loyaltyPrograms: (queryKey: string) => {
    return {
      queryKey: [queryKey],
    };
  },
  awardSearch: (url: string) => {
    return {
      queryKey: [url],
    };
  },
});

export const travelDestinations = createQueryKeys('travelDestinations', {
  search: (queryKey: string) => {
    return {
      queryKey: [queryKey],
    };
  },
});

export const travelCars = createQueryKeys('travelCars', {
  terms: (bookingKey: string) => {
    return {
      queryKey: ['cars-terms', bookingKey],
    };
  },
});

export const shoppingCart = createQueryKeys('shoppingCart', {
  detail: (cartItemType: string) => {
    return {
      queryKey: [cartItemType],
    };
  },
});

export const mapboxLocations = createQueryKeys('mapboxLocation', {
  search: (searchQuery: string) => {
    return {
      queryKey: [searchQuery],
    };
  },
});

export const mapboxTimezones = createQueryKeys('mapboxTimezones', {
  search: (lonlat: string) => {
    return {
      queryKey: [lonlat],
    };
  },
});

export const mapboxGeocodeReverse = createQueryKeys('mapboxGeocodeReverse', {
  search: (searchQuery: string) => {
    return {
      queryKey: [searchQuery],
    };
  },
});

export const pointsAccounts = createQueryKeys('pointsAccounts', {
  userId: null,
});

export const upcomingBookings = createQueryKeys('upcomingBookings', {
  list: <T>(searchParams: SearchParam<T>) => {
    return {
      queryKey: [searchParams],
    };
  },
});

export const AscendaChatBot = createQueryKeys('AscendaChatBot', {
  list: <T>(searchParams: SearchParam<T>) => {
    return {
      queryKey: [searchParams],
    };
  },
});

export const chatBotSuggestedPrompts = createQueryKeys(
  'chatBotSuggestedPrompts',
  {
    list: <T>(searchParams: SearchParam<T>) => {
      return {
        queryKey: [searchParams],
      };
    },
  },
);

export const freeformCash = createQueryKeys('freeformCash', {
  calculate: (bookingKey: string, freeformPoints: number) => {
    return {
      queryKey: [bookingKey, freeformPoints],
    };
  },
});

export const giftCardCampaign = createQueryKeys('giftCardCampaigns', {
  list: <T>(searchParams: SearchParam<T>) => {
    return {
      queryKey: [searchParams],
    };
  },
  detail: (giftCardId: string) => ({
    queryKey: [giftCardId],
  }),
});

export const campaignDiscount = createQueryKeys('campaignDiscount', {
  calculate: <T>(searchParams: SearchParam<T>) => {
    return {
      queryKey: [searchParams],
    };
  },
});

export const offerBootstrap = createQueryKeys('offerBootstrap', {
  list: ['offerBootstrap'],
});

export const queryKeys = mergeQueryKeys(
  offerBootstrap,
  car,
  carLocation,
  dining,
  freeformCash,
  giftCardKeys,
  giftCardCampaign,
  campaignDiscount,
  loyaltyProgram,
  mapboxLocations,
  mapboxGeocodeReverse,
  mapboxTimezones,
  membership,
  offer,
  order,
  orderItem,
  redeemAgainItem,
  availableToRedeemItem,
  pointsAccounts,
  pointsActivity,
  pointsActivity,
  pointsSummary,
  productListing,
  productListing,
  question,
  shoppingCart,
  staticDocument,
  topic,
  travelCars,
  travelDestinations,
  travelFlights,
  upcomingBookings,
  AscendaChatBot,
  chatBotSuggestedPrompts,
);
