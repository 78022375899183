import { IconStyle } from '@/config-schema/rewards-central-config'; 
export const fontawesomeIconMap: Map<IconStyle, string> = new Map(); 
fontawesomeIconMap.set('duotone', '/assets/icon/sprites/output/duotone.6ed9b0.svg'); 
fontawesomeIconMap.set('thin', '/assets/icon/sprites/output/thin.7c364a.svg'); 
fontawesomeIconMap.set('light', '/assets/icon/sprites/output/light.de7d48.svg'); 
fontawesomeIconMap.set('regular', '/assets/icon/sprites/output/regular.e6f822.svg'); 
fontawesomeIconMap.set('solid', '/assets/icon/sprites/output/solid.4c294f.svg'); 
fontawesomeIconMap.set('sharp-duotone-solid', '/assets/icon/sprites/output/sharp-duotone-solid.1bdcd9.svg'); 
fontawesomeIconMap.set('sharp-light', '/assets/icon/sprites/output/sharp-light.3ebfe5.svg'); 
fontawesomeIconMap.set('sharp-regular', '/assets/icon/sprites/output/sharp-regular.155e8a.svg'); 
fontawesomeIconMap.set('sharp-solid', '/assets/icon/sprites/output/sharp-solid.1e9694.svg'); 
fontawesomeIconMap.set('sharp-thin', '/assets/icon/sprites/output/sharp-thin.4c908c.svg')