import { localeRegex } from '@/i18n/constants';
import { Locale } from '@/i18n/types';
import { getClientUrl } from '@/utils/get-client-url';

export function handleRedirectAfterLogin({
  locale,
  pathname,
  savedUrl,
  searchParams,
  homePagePathname,
}: {
  locale?: Locale;
  pathname: string;
  savedUrl?: string;
  searchParams: URLSearchParams;
  homePagePathname?: string;
}) {
  let nextPathname = pathname;
  let shouldRedirect = false;

  if (savedUrl) {
    const savedUrlObject = new URL(savedUrl);
    if (savedUrlObject.pathname !== '/' || homePagePathname) {
      nextPathname = savedUrlObject.pathname;
      console.log('handleRedirectAfterLogin #19', {
        nextPathname,
        pathname,
      });
      shouldRedirect = true;
    }
  }

  const newSearchParams = new URLSearchParams(searchParams);
  if (newSearchParams.get('state')) {
    shouldRedirect = true;
    // clean up authentication params
    newSearchParams.delete('code');
    newSearchParams.delete('state');
    console.log('handleRedirectAfterLogin #33', {
      nextPathname,
      pathname,
    });
    return {
      shouldRedirect,
      nextPathname: joinUrlWithSearchParams({
        locale,
        homePagePathname,
        pathname: nextPathname,
        searchParams: newSearchParams,
      }),
    };
  }
  // else {
  //   // Do not redirect if the next pathname is the same as the current one
  //   // to avoid redirect loop
  //   if (nextPathname !== pathname) {
  //     return {
  //       shouldRedirect,
  //       nextPathname: joinUrlWithSearchParams({
  //         locale,
  //         homePagePathname,
  //         pathname: nextPathname, searchParams: newSearchParams
  //       }),
  //     }
  //   }
  // }

  // This is not a good way to handle redirect
  // we should refactor this logic later
  if (nextPathname === '/' && homePagePathname) {
    console.log('handleRedirectAfterLogin #63', {
      nextPathname,
      pathname,
    });
    shouldRedirect = true;
  }

  return {
    shouldRedirect,
    nextPathname: joinUrlWithSearchParams({
      locale,
      homePagePathname,
      pathname: nextPathname,
      searchParams: newSearchParams,
    }),
  };
}

function joinUrlWithSearchParams({
  locale,
  pathname,
  homePagePathname,
  searchParams,
}: {
  locale?: Locale;
  pathname: string;
  homePagePathname?: string;
  searchParams?: URLSearchParams;
}) {
  let nextPathname = pathname;
  // support custom home page path name
  // for example Robinhood doesn't have home page
  // and we need to redirect to the /travel/flights
  if (homePagePathname && pathname === '/') {
    nextPathname = homePagePathname;
  }

  /**
   * NOTE(sontruong):
   * The code below is used for binding user preferred locale to the URL before redirecting.
   * User preferred locales come from /auth/oauth/token API.
   *
   * Please go to localeRegex definition to have more details.
   *
   * This is the best workaround solution for now, I will improve it later when I have time.
   * Reference: https://www.notion.so/kaligo/RC-FE-Binding-user-preferred-locale-from-token-API-call-10c31c5427fd80628371ee89629e0ee8?pvs=4
   *
   * @example
   * // Binding a user preferred locale to a pathname
   * const userPreferredLocale = 'en-US';
   * const url = 'https://abc.com/zh-CN?test=test';
   *
   * const pathname = new URL(url).pathname;
   * console.log(pathname); // '/zh-CN'
   *
   * const maybeLocale = pathname.split('/')[1];
   * console.log(maybeLocale); // 'zh-CN'
   *
   * const isLocale = localeRegex.test(maybeLocale);
   * console.log(isLocale); // true
   *
   * pathname = isLocale && userPreferredLocale ? pathname.replace(maybeLocale, userPreferredLocale) : pathname;
   * console.log(pathname); // '/en-US'
   */
  const baseUrl = getClientUrl();
  nextPathname = new URL(nextPathname, baseUrl).pathname;

  const maybeLocale = nextPathname.split('/')[1];
  const isLocale = localeRegex.test(maybeLocale);

  nextPathname =
    isLocale && locale
      ? nextPathname.replace(maybeLocale, locale)
      : nextPathname;

  // use toString() here to check the URLSearchParams is empty or not
  // since .size property is always undefined in vitest
  if (searchParams?.toString()) {
    return `${nextPathname}?${searchParams.toString()}`;
  }
  return nextPathname;
}
