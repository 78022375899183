export const Flag = {
  AppleMerchandise: 'apple-merchandise',
  AwardSearch: 'award_search',
  DiningOffers: 'dining-offers',
  FetchUiSettingsFromMC: 'fetch_ui_settings_from_mc',
  FrequentFlyerProgram: 'frequent_flyer_program',
  GiftCardPersonalization: 'gift-card-personalization',
  M2020ListingRedirect: 'm2020_listing_redirect',
  M2020Tenants: 'm2020_tenants',
  MarketingConsent: 'marketing_consent',
  OffersCurrentLocationEnabled: 'offers_current_location_enabled',
  RcCampaigns: 'rc_campaigns',
  RcFEDetailsV2: 'rc_fe_details_v2',
  RcFeI18n: 'rc_fe_i18n',
  RcGiftCardMaintenance: 'rc_gift_card_maintenance',
  RcGiftcardCampaigns: 'rc_giftcard_campaigns',
  RcOffersMaintenance: 'rc_offers_maintenance',
  RcRecommendedForUser: 'rc_recommended_for_user',
  RcRecommendedProducts: 'rc_recommended_products',
  RedeemableM2020: 'redeemable_m2020',
  RewardsPortal2_0R1: 'rewards_portal_2.0_r1',
  RewardsPortal2_0R2: 'rewards_portal_2.0_r2',
  RobinhoodUatFlights: 'robinhood_uat_flights',
  TESingleSupplierExperiment: 'te_single_supplier_experiment',
  listingHotelsMapView: 'listing_hotels_map_view',
  SentryUserFeedback: 'rc_sentry_user_feedback',
} as const;

export type Flag = (typeof Flag)[keyof typeof Flag];
