/**
 * This code was generated by v0 by Vercel.
 * @see https://v0.dev/t/NixTqUQhBUF
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */

/** Add fonts into your Next.js project:

import { Inter } from 'next/font/google'

inter({
  subsets: ['latin'],
  display: 'swap',
})

To read more about using these font, please visit the Next.js documentation:
- App Directory: https://nextjs.org/docs/app/building-your-application/optimizing/fonts
- Pages Directory: https://nextjs.org/docs/pages/building-your-application/optimizing/fonts
**/

'use client';

import { useChatbotContext } from '@/app/chatbot';
import Drawer, {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderCloseButton,
} from '@/design-system-components/drawer/drawer';
import { ChatBotResponse, useChatBot } from '@/hooks/chat-bot/use-chat-bot';
import { useCleanChatBot } from '@/hooks/chat-bot/use-clean-chat-bot';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useState } from 'react';
import { DialogTrigger } from 'react-aria-components';
import { v4 as uuidv4 } from 'uuid';
import { ChatBotBody } from './chat-bot-body';
import { ChatBotInput } from './chat-bot-input';

export interface ChatDataInterface {
  id: string;
  type: 'sender' | 'receiver';
  userId: string;
  content: string;
}

export function chatMessageTransform(
  chatData: ChatBotResponse['data'],
): Array<ChatDataInterface> {
  return chatData.flatMap((message) => [
    createChatDataItem('sender', 'currentUser', message.userInput),
    createChatDataItem('receiver', 'Bot', message.assistantOutput),
  ]);
}

export function ChatBotAscenda() {
  const t = useTranslations('chatBot');
  const chatBotTitleText = t('chatBotTitle');
  const { isOpen, close, fromAirport, travelType } = useChatbotContext();
  const [finishedCleaning, setFinishedCleaning] = useState(false);
  const [chatData, setChatData] = useState<ChatDataInterface[]>([]);
  const chatBotMutation = useChatBot();
  const {
    mutateAsync: cleanDataMutation,
    isLoading: cleaning,
    reset: resetCleanDataMutation,
  } = useCleanChatBot();

  useEffect(() => {
    new Image().src = '/assets/icon/chat-bot/sparkle.svg';
  }, []);

  useEffect(() => {
    if (isOpen === true) {
      cleanDataMutation().then(() => {
        setFinishedCleaning(true);
      });
      setChatData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onNewMessage = useCallback(
    async (message: string) => {
      setChatData((prev) => [
        ...prev,
        createChatDataItem('sender', 'currentUser', message),
      ]);

      const newMessages = await chatBotMutation.mutateAsync({
        message,
        context: {
          fromAirport: fromAirport?.code ?? '',
          fromCity: fromAirport?.city ?? '',
          travelType,
        },
      });
      const newChatData = chatMessageTransform(newMessages.data.slice(-1));

      setChatData((prev) => [...prev, ...newChatData.slice(-1)]);
    },
    [chatBotMutation, fromAirport?.city, fromAirport?.code],
  );

  const onClose = useCallback(() => {
    setFinishedCleaning(false);
    close();

    resetCleanDataMutation();
    chatBotMutation.reset();
  }, [chatBotMutation, close, resetCleanDataMutation]);

  return (
    <div className="fixed bottom-0 right-0 top-0 z-20">
      <DialogTrigger isOpen={isOpen} onOpenChange={onClose}>
        <Drawer variant="right">
          <DrawerHeader
            onClose={onClose}
            className="flex items-center justify-between"
          >
            <h3 className="text-lg-bold">{chatBotTitleText}</h3>
            <DrawerHeaderCloseButton
              drawerVariant="modal"
              onCloseHandler={onClose}
              className="flex h-full items-center justify-center"
            />
          </DrawerHeader>
          <DrawerBody className="!p-0">
            <ChatBotBody
              chatData={chatData}
              isWaitingAIResponse={chatBotMutation.isLoading}
            />
            <ChatBotInput
              onNewMessage={onNewMessage}
              isWaitingAIResponse={chatBotMutation.isLoading}
              isCleaningOldChat={cleaning}
              isFinishedCleaning={finishedCleaning}
            />
          </DrawerBody>
        </Drawer>
      </DialogTrigger>
    </div>
  );
}

function createChatDataItem(
  type: 'sender' | 'receiver',
  userId: string,
  userInput: string,
): ChatDataInterface {
  return {
    id: uuidv4(),
    type,
    userId,
    content: userInput,
  };
}
function cleanDataMutation() {
  throw new Error('Function not implemented.');
}
