'use client';

import { ChatBotAscenda } from '@/components/chat-bot/chat-bot';
import { useFlagsReady } from '@/hooks/flags-ready/use-flags-ready';
import { AirportItem } from '@/schema/flights/airports/algolia-airports.schema';
import { TravelType } from '@/schema/travel.schema';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

interface ChatbotContextType {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  userPickedPrompt: string;
  fromAirport?: AirportItem;
  travelType: TravelType;
  setUserPickedPrompt: Dispatch<SetStateAction<string>>;
  setFromAirport: Dispatch<SetStateAction<AirportItem | undefined>>;
  setTravelType: Dispatch<SetStateAction<TravelType>>;
}

const ChatbotContext = createContext<ChatbotContextType | undefined>(undefined);

export function ChatbotProvider({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const [userPickedPrompt, setUserPickedPrompt] = useState<string>('');
  const [fromAirport, setFromAirport] = useState<AirportItem | undefined>();
  const [travelType, setTravelType] = useState<TravelType>(TravelType.FLIGHT);

  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);

  return (
    <ChatbotContext.Provider
      value={{
        isOpen,
        open,
        close,
        userPickedPrompt,
        setUserPickedPrompt,
        fromAirport,
        setFromAirport,
        travelType,
        setTravelType,
      }}
    >
      {children}
    </ChatbotContext.Provider>
  );
}

export function useChatbotContext() {
  const context = useContext(ChatbotContext);
  if (context === undefined) {
    throw new Error('useChatbot must be used within a ChatbotProvider');
  }
  return context;
}

export function Chatbot() {
  const isM2020 = useFlagsReady('m2020_tenants');

  return isM2020 ? <ChatBotAscenda /> : null;
}
